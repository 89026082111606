var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          staticClass: "send-task",
          attrs: {
            width: "38%",
            height: "auto",
            title: _vm.title,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: true,
            position: _vm.position,
            transfer: "",
          },
          on: {
            close: _vm.handleCloseTaskModal,
            hide: _vm.handleCloseTaskModal,
          },
          model: {
            value: _vm.ModalVisible,
            callback: function ($$v) {
              _vm.ModalVisible = $$v
            },
            expression: "ModalVisible",
          },
        },
        [
          _c("a-spin", { attrs: { spinning: _vm.spinning } }, [
            _c(
              "div",
              [
                _c(
                  "a-form-model",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      layout: "horizontal",
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                    },
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "模板名称", prop: "taskName" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.form.taskName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "taskName", $$v)
                            },
                            expression: "form.taskName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "任务类型", prop: "taskType" } },
                      [
                        _vm.isEdit
                          ? _c("span", [_vm._v(_vm._s(_vm.getTaskTypeName()))])
                          : _c(
                              "a-radio-group",
                              {
                                attrs: { "button-style": "solid" },
                                on: { change: _vm.handleTaskTypeChange },
                                model: {
                                  value: _vm.form.taskType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "taskType", $$v)
                                  },
                                  expression: "form.taskType",
                                },
                              },
                              _vm._l(_vm.taskTypeOptions, function (d, index) {
                                return _c(
                                  "a-radio-button",
                                  { key: index, attrs: { value: d.dictValue } },
                                  [_vm._v(_vm._s(d.dictLabel))]
                                )
                              }),
                              1
                            ),
                      ],
                      1
                    ),
                    ["0", "2"].indexOf(_vm.form.taskType) >= 0
                      ? [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "地图ID", prop: "mapId" } },
                            [
                              _vm.isEdit
                                ? _c("span", [_vm._v(_vm._s(_vm.form.mapId))])
                                : _c("a-auto-complete", {
                                    attrs: {
                                      "data-source": _vm.mapIdArray,
                                      placeholder: "请输入mapId模糊查找",
                                      allowClear: "",
                                    },
                                    on: { change: _vm.handleMapIdChange },
                                    model: {
                                      value: _vm.form.mapId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "mapId", $$v)
                                      },
                                      expression: "form.mapId",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: "地图版本", prop: "mapVersion" },
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.form.mapVersion)),
                                  ])
                                : _c("a-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      allowClear: "",
                                    },
                                    model: {
                                      value: _vm.form.mapVersion,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "mapVersion", $$v)
                                      },
                                      expression: "form.mapVersion",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm.form.taskType === "0"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "循迹路径目录名称",
                                    prop: "routeFolderName",
                                  },
                                },
                                [
                                  _vm.isEdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.routeFolderName)
                                        ),
                                      ])
                                    : _c("a-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.form.routeFolderName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "routeFolderName",
                                              $$v
                                            )
                                          },
                                          expression: "form.routeFolderName",
                                        },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.form.taskType === "1"
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "选择路线", prop: "taskRouteId" } },
                          [
                            _vm.isEdit
                              ? _c("span", [_vm._v(_vm._s(_vm.form.routeName))])
                              : _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      "show-search": "",
                                      placeholder: "请输入路线名称模糊查找",
                                      "default-active-first-option": false,
                                      "show-arrow": false,
                                      "filter-option": false,
                                      "not-found-content": null,
                                      loading: _vm.loading,
                                    },
                                    on: {
                                      search: _vm.handleRouteSearch,
                                      change: _vm.handleRouteChange,
                                    },
                                    model: {
                                      value: _vm.form.taskRouteId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "taskRouteId", $$v)
                                      },
                                      expression: "form.taskRouteId",
                                    },
                                  },
                                  _vm._l(_vm.routeArray, function (d) {
                                    return _c(
                                      "a-select-option",
                                      { key: d.id },
                                      [_vm._v(" " + _vm._s(d.routeName) + " ")]
                                    )
                                  }),
                                  1
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.taskType === "2"
                      ? [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "目标位置" } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleMapDraw },
                                },
                                [_vm._v("去地图选点")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "目标位置经度", prop: "lon" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入经度",
                                },
                                model: {
                                  value: _vm.form.lon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "lon", $$v)
                                  },
                                  expression: "form.lon",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "目标位置纬度", prop: "lat" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入纬度",
                                },
                                model: {
                                  value: _vm.form.lat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "lat", $$v)
                                  },
                                  expression: "form.lat",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "任务备注", prop: "remark" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "任务里程(km)", prop: "mileage" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.form.mileage,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mileage", $$v)
                            },
                            expression: "form.mileage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("dialog-bottom-bar", {
                  attrs: { "primary-text": "确定", "second-text": "取消" },
                  on: {
                    onConfirm: _vm.submitForm,
                    onCancel: _vm.handleCancelTask,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("GetLocationOnMap", {
        ref: "mapRef",
        on: { onLocation: _vm.handleGetLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }